import App from './App.svelte'
// import * as Sentry from '@sentry/svelte'

// Initialize the Sentry SDK
/*
if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: 'https://3311e4fd48fb402698009c5cb647a8d7@o4504324594794496.ingest.sentry.io/4504324607705088',
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay()
    ],
    sampleRate: 1, // error logging
    tracesSampleRate: 0.01, // performance monitoring
    replaysSessionSampleRate: 0, // replays
    replaysOnErrorSampleRate: 1 // replays with an error
  })
}
*/

const app = new App({ target: document.getElementById('app') })

export default app
